import React, { useContext } from 'react';
import './FooterTop.styles.scss';
import IconMap from '../../../assets/Icons/icon-map.svg';
import IconPhone from '../../../assets/Icons/icon-phone.svg';
import IconShield from '../../../assets/Icons/icon-shield.svg';
import LogoObjBranco from '../../../assets/Logo/logo-branca-grande.png';
import IconFB from '../../../assets/Icons/icon-social-ig.svg';
import IconYT from '../../../assets/Icons/icon-social-yt.svg';
import IconIG from '../../../assets/Icons/icon-social-fb.svg';
import { Link, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../contexts/AccountContext';

const FooterTop = () => {
	const { isLoggedIn, toggleAccountModal, termsContracts } =
		useContext(AccountContext);

	const navigate = useNavigate();
	const navigateConta = () => {
		navigate('/conta');
	};

	const accountModalHandler = () => {
		if (isLoggedIn === true) {
			navigateConta();
		} else {
			toggleAccountModal();
		}
	};

	return (
		<>
			<div className="footer-top-wrapper">
				<div className="footer-top-container">
					<div className="footer-logo-container">
						<div className="footer-logo-top-container">
							<div className="footer-logo">
								<img src={LogoObjBranco} alt="" />
							</div>
							<div className="footer-socials">
								{/* <a href="https://facebook.com/escolaexpert" target='_blank' rel="noreferrer">
									<img src={IconFB} alt="" />
								</a>
								<a href="https://youtube.com/c/escolaexpert" target='_blank' rel="noreferrer">
									<img src={IconYT} alt="" />
								</a>
								<a href="https://instagram.com/escolaexpert" target='_blank' rel="noreferrer">
									<img src={IconIG} alt="" />
								</a> */}
							</div>
						</div>
						{/* <div className="footer-unidade-container">
							<div className="footer-unidade">
								<h3>Campus Teresina</h3>
								<p>
									Rua General Lages, 2205
									Horto Florestal<br />
									(86) 3025-0040
								</p>
							</div>
						</div> */}
					</div>
					<div className="links-container">
						<h2 className="ul-title">Links Úteis</h2>
						<ul>
							<li style={{ cursor: 'pointer' }} onClick={accountModalHandler}>
								<h3>Minha Conta</h3>
							</li>
							<li>
								<Link to={'/compra-rapida'}>
									<h3>Compra Rápida</h3>
								</Link>
							</li>
							<li>
								<Link to={'/faq'}>
									<h3>F.A.Q.</h3>
								</Link>
							</li>
							<li>
								<Link to={'/atendimento'}>
									<h3>Atendimento</h3>
								</Link>
							</li>
							<li>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={termsContracts}>
									<h3>Termos & Condições</h3>
								</a>
							</li>
						</ul>
					</div>
					<div className="info-container">
						<h2 className="ul-title">Informações da Loja</h2>
						<ul>
							<li>
								<div className="endereço-container">
									<img src={IconMap} alt="mapa" />
									<h3>
									Rua General Lages, 2205<br />
									 Horto Florestal - Teresina - PI
									</h3>
								</div>
							</li>
							<li>
								<div className="telefone-container">
									<img src={IconPhone} alt="phone" />
									<h3>(86) 3025-0040</h3>
								</div>
							</li>
							<li>
								<div className="segurança-container">
									<img src={IconShield} alt="secure" />
									<div className="segurança-text">
										<h3>100% Seguro</h3>
										<p>Suas informações estão criptografadas e seguras.</p>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default FooterTop;
